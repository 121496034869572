
.carousel-inner .carousel-item video{
    object-fit: cover;
    height: 100vh;
    width: 100%;
  }
  
  .carousel-inner{
    height: 100vh !important;
  }
  body {
    overflow: hidden;
  }
  
  .carousel-caption{
  bottom: 7rem !important;
  }
  .carousel-indicators{
    bottom: 6rem !important;
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }
  
  .position-set{
    position: absolute;
    top:80px;
    right: 5%;
    left: 5%;
    z-index: 9;
  }
  .font-setting{
    font-size: 3rem;
  }
  .p-style{
    letter-spacing: 6px;
    font-weight: 600;
    font-size: 20px;
  
  }
  .text-p-a{
    position: absolute;
  top: 165px;
  left:25px;
  z-index: 9;
  }
  .cuursor_P{
    cursor: pointer;
    
  }
  .para-style{
    font-size: 30px;
    font-weight: 900px;
    color: #4aff18;
    text-shadow: 0 0 5px black, 0 0 10px black, 0 0 15px black;
  }
  .carousel-indicators{
    display: none !important;
  }
  .para-sub-style{
    font-size: 16px;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    font-size: 26px;
    color: #4aff18;
    text-shadow: 0 0 5px black, 0 0 10px black, 0 0 15px black;
  }
  
  .site-style{
    cursor: pointer;
    color: white !important;
    font-weight: 400;
  }
  
  
  .box {
  cursor: pointer;
    width: 7%;
    margin: auto;
    border-bottom: 2px solid black;
    border-bottom-color: #4aff18;
    animation: color-change 3s infinite;
  }
  
  .box a{
    color:#4aff18;}
    .box a:hover{
      color:#4aff18;}
  @keyframes color-change {
    0% {
       border-bottom-color: #4aff18;
    }
    50% {
       border-bottom-color: transparent;
    }
    100% {
       border-bottom-color: yellow;
  
    }
  
  }


  @media only screen and (max-width:605px){

    .para-style{
  font-size: 15px;
    }
    .para-sub-style{
      font-size: 12px;
      margin-top: 1%;
    }
  
  
    .countdown-value{
      font-size: 2rem !important;
  }
  .countdown-timer{
      display: -webkit-inline-box;
  
  }
  
  .position-set{
  
    top:5px;
   
  }
  
  .text-p-a{
    top:130px;
  }
  .p-style{
    font-size: 14px;
    letter-spacing: 2px;
    margin-top: 2px !important;
  }
  
  .countdown-label {
      font-size:15px;
  
    }
  
    .countdown-container{
      margin-top: 15rem;
    }
  
  .font-setting{
  font-size: 25px !important;
  }
  .mobile-font{
    font-size: 14px;
  }
  .box{
  width:25%;
  }
  }


