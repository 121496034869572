.countdown-container {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .countdown-item {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }
  
  .countdown-value {
    font-size: 5.5rem !important;
    font-weight: bold;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 10px #6ea8d9, 0 0 20px #6ea8d9, 0 0 20px #6ea8d9, 0 0 25px #6ea8d9, 0 0 55px #6ea8d9;
  }
  
  .countdown-label {
    font-size:18px;
    text-transform: uppercase;
    color:white;
    font-weight: 600;
  }

 

  .text-3d::before, .text-3d::after {
    position: absolute;
    content: attr(data-text);
    top: 0;
    left: 0;
    transform: translateZ(-10px);
  }

  .text-3d::before {
    color: #4aff18;
    z-index: -1;
    text-shadow: 0 0 5px black, 0 0 10px black, 0 0 15px black;
  
  }
  .text-poweredby {
    color: #4aff18;
    text-shadow: 0 0 5px black, 0 0 10px black, 0 0 15px black;
    font-weight: 300;
  
  }



  a{text-decoration: none;}

  .text-3d::after {
    color: #07f59e;
    z-index: -2;
    filter: blur(16px);
  }

@media only screen and (max-width:605px){

  .para-style{
font-size: 15px;
  }
  .para-sub-style{
    font-size: 12px;
    margin-top: 1%;
  }


  .countdown-value{
    font-size: 2rem !important;
}
.countdown-timer{
    display: -webkit-inline-box;

}

.position-set{

  top:5px;
 
}

.text-p-a{
  top:130px;
}
.p-style{
  font-size: 14px;
  letter-spacing: 2px;
  margin-top: 2px !important;
}

.countdown-label {
    font-size:15px;

  }

  .countdown-container{
    margin-top: 15rem;
  }

.font-setting{
font-size: 25px !important;
}
.mobile-font{
  font-size: 14px;
}
.box{
width:25%;
}
}


  @media only screen and (min-width:606px) and (max-width:767px){
    .countdown-value{
        font-size: 5rem !important;
    }
    .countdown-timer{
        display: -webkit-inline-box;

    }

    .countdown-label {
        font-size:15px;
   
      }

      .countdown-container{
        margin-top: 18rem;
      }

.font-setting{
  font-size: 25px !important;
}
  }

  @media only screen and (min-width:768px) and (max-width:991px){
    .countdown-container{
      margin-top: 25rem;
    }
    .countdown-value{
      font-size: 5rem !important;
  }
  .text-p-a{
    top:190px
  }
  .box{
    width:13%;
  }
  .para-sub-style{
    margin-top: 4%;
  }

  }

  @media only screen and (min-width:992px) and (max-width:1447px){

  }