.icon_setting{
    font-size: 26px!important;
    margin-top: 4px;
}
.logo{
    font-weight: 900;
    color:#0353A4;
    font-size: 1.4rem;
    font-family: Arial, Helvetica, sans-serif;
    cursor:pointer  
}

.nav-absolute{
    
        background: white !important;
        position: fixed;
        z-index: 2;
}

.text-style{
    color: #0353A4!important;
}

.signup-btn{
    background-color: #0353A4;
    color: white;
    border: 1px solid #0353A4;
    border-radius: 50px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    
}

.form-control{
    border-radius: 12px;
}

.navbar_f{
    font-size: 1rem !important;
    font-weight: 500 !important;
   
}
.container-border-b {
    border-bottom: 1px solid #c4c4c4 ;
}


@media (max-width: 575px) and (min-width: 338px){
    .logo {
        font-size: 1rem!important;
    }
    .smallFont{
        font-size: 13px!important;
    }
    .smallFonthome{
        font-size: 15px!important;
    }
    .img-width {
        width: 23px!important;
    }
}
 
@media (max-width: 337px) and (min-width: 320px){
    .logo {
        font-size: 0.7rem!important;
    }
    .smallFont{
        font-size: 11px!important;
    }
    .smallFonthome{
        font-size: 14px!important;
    }
    .img-width {
        width: 20px!important;
    }
}

.section-para{
    color: #406883;
    font-size: 18px;
    display: flex;
    justify-content: center;
    font-weight: 500;
}
.section-one{
    border: 1px solid #CACACA;
 background: #FFFFFF;
 border-radius: 5px;
    
}
.news-heading{
    border-bottom: 2px solid #0353A4;
    font-size: 18px;
    font-weight: 400;
    width: 22%;
}
.section-two{
    border: 1px solid #CACACA;
    border-radius: 5px;
}
.input-group input:focus{
box-shadow: none!important;
}
.search-btn{
    background-color: #0353A4;
    border-radius: 0px;
    color:white;
    border-radius: 6px;
}
.search-btn:focus{
box-shadow: none !important;
}

.search-btn:hover{
    color :white !important;
}

.input-group input{
    border: none !important;
}

.input-group{
    
    border-radius: 12px;
}

.cards-bg{
    background: linear-gradient(180deg, #002040 0%, #000101 100%);
    box-shadow: 0px 0px 16px 0.5px #00000040;
border-radius: 6px;
}

.cards-small-font{
    font-size: 12px;
}

.card-div-img{
    background: #F4F4F4;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 180px;
   
    

}

.card-img-top{
 
 
}
.card-body{
    border-top:1px solid black;
}

.custom-card{


}
.card{
    border: 0px;
}
.custom-card-width{
    width: 17rem;
    box-shadow: 0px 0px 16px 0.5px #00000040;
    border-radius: 6px !important;
}
.card-text{
    font-size: 10px !important;
    color: black !important;
}
.card-date{
    border-top: 1px solid #A7A7A7;
    font-size: 10px;
    font-weight: 700;
    color:black !important;
}

.custom-card{
    cursor: pointer;
}

.news-height{
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.card-title{
    color:black !important;
}

@media only screen and (max-width:499px){
    .section-para{
        font-size: 10px;
    }
    .news-heading{
        width: 100%;
        margin-bottom: 5% !important;
    }
    .news-height{
        height: 100vh;
    }
    .custom-card-width{
        width: auto;
    }

    
}

@media only screen and (min-width:1200px) and (max-width:1399px){
.card-title{
    font-size: 14px;
}
.custom-card-width{
    width: 15rem;
}
}


@media only screen and (min-width:992px) and (max-width:1199px){
    .card-title{
        font-size: 11px;
    }
    .news-heading{
  width: 27%;

    }

    .custom-card-width{
        width: 13rem;
    }
    }

    @media only screen and (max-width:991px){
        .news-heading{
            margin-bottom: 5% !important;
        }
    }

